<template>
  <div class="shortLinkManager">
    <div class="flex">
      <div class="search">
        <div>链接名称:</div>
        <el-input v-model="linkName" style="width: 200px;margin: 0 10px;" clearable></el-input>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
      </div>
      <el-button @click="handleEdit(0)">新增</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%;margin-top: 20px;">
      <template v-for="item in tableHead" :key="item.props">
        <el-table-column :prop="item.props" :label="item.label" :width="item.width" align="center">
          <template #default="{ row }">
            <div v-if="item.props">{{ row[item.props] }}</div>
            <div v-if="item.props == 'button'" class="setting">
              <div @click="handleEdit(1, row)">编辑</div>
              <div @click="handleEdit(2, row)">详情</div>
            </div>
            <div v-if="item.props == 'refresh'" @click="handleRefresh(row)">
              <el-icon color="#409efc" size="20" class="no-inherit">
                <RefreshRight />
              </el-icon>
            </div>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <Pagination :pagination="pagination" @handleChange="handleChangePage"></Pagination>
    <el-dialog v-model="dialogVisible" :title="title" width="500" :destroy-on-close="true" :before-close="handleClose">
      <el-form
        ref="ruleFormRef"
        style="max-width: 600px"
        :model="formData"
        :rules="rules"
        label-width="200px"
        class="demo-ruleForm"
        :size="formSize"
        status-icon
      >
        <el-form-item label="链接名称" prop="linkName">
          <el-input v-model="formData.linkName" :disabled="isDisabled" type="textarea" />
        </el-form-item>
        <el-form-item label="关联页面" prop="linkPage">
          <el-input v-model="formData.linkPage" :disabled="isDisabled" type="textarea" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="handleSubmit()">
            保存
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { ElMessage } from 'element-plus'
import API from '@/plugin/cms-manager/service/api.js'
import Pagination from '@/component/base/Pagination1/index'
export default {
  components: { Pagination },
  data() {
    return {
      linkName: '',
      title: '新增链接',
      dialogVisible: false,
      formData: {},
      tableData: [
        {
          linkPage: '2016-05-03',
          linkName: 'Tom',
          link: 'No. 189, Grove St, Los Angeles',
        },
        {
          linkPage: '2016-05-03',
          linkName: 'Tom',
          link: 'No. 189, Grove St, Los Angeles',
        },
        {
          linkPage: '2016-05-03',
          linkName: 'Tom',
          link: 'No. 189, Grove St, Los Angeles',
        },
      ],
      tableHead: [
        {
          props: 'linkName',
          label: '链接名称',
        },
        {
          props: 'linkPage',
          label: '关联页面',
        },
        {
          props: 'shortChain',
          label: '短链接',
        },
        {
          props: 'refresh',
          label: '刷新短链接有效期',
        },
        {
          props: 'button',
          label: '操作',
        },
      ],
      rules: {
        linkName: [{ required: true, message: '请输入链接名称', trigger: 'blur' }],
        linkPage: [{ required: true, message: '请输入关联页面', trigger: 'blur' }],
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        pageCount: 5,
        pageSizes: [10, 20, 30, 40, 50],
      },
    }
  },
  mounted() {
    this.queryList()
  },
  methods: {
    // 刷新
    async handleRefresh(row) {
      const params = {
        id: row.id,
        linkName: row.linkName,
        linkPage: row.linkPage,
      }
      let res = await API.deleteVodSong(params)
      if (res.code == '200') {
        ElMessage.success('刷新成功')
      }
    },
    // 查询
    handleSearch: _.throttle(function() {
      this.pagination.pageNum = 1
      this.queryList()
    }, 1000),
    // 重置
    handleReset: _.throttle(function() {
      this.linkName = ''
      this.pagination.pageNum = 1
      this.queryList()
    }, 1000),
    queryList() {
      const params = { linkName: this.linkName, ...this.pagination }
      API.queryLink(params).then(res => {
        if (res.code == '200') {
          this.tableData = res?.data?.records || []
          this.pagination.total = res?.data?.totalRecords || 0
        } else {
          ElMessage.error(res.msg)
        }
      })
    },
    handleChangePage(pages) {
      Object.assign(this.pagination, pages)
      this.queryList()
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs.ruleFormRef.resetFields()
      this.formData = {}
    },
    async handleSubmit() {
      if (this.title == '链接详情') {
        this.dialogVisible = false
        return
      } else {
        await this.$refs.ruleFormRef.validate(async (valid, fields) => {
          if (valid) {
            const params = { ...this.formData }
            let res = await API.updateLink(params)
            if (res.code == '200') {
              this.dialogVisible = false
              this.pagination.pageNum = 1
              if (this.title == '新增链接') {
                ElMessage.success('新增成功')
              } else {
                ElMessage.success('更新成功')
              }
              this.queryList()
            } else {
              ElMessage.error(res.msg)
            }
          } else {
            console.log('error submit!', fields)
          }
        })
      }
    },
    handleEdit(val, row) {
      console.log(row)
      this.formData = { ...row }
      const enmu = {
        0: '新增链接',
        1: '编辑链接',
        2: '链接详情',
      }
      this.title = enmu[val]
      this.dialogVisible = true
    },
  },
  computed: {
    isDisabled() {
      if (this.title == '链接详情') {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.shortLinkManager {
  margin: 20px;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-button {
      width: 80px;
    }
  }
  .search {
    display: flex;
    align-items: center;
    .el-button {
      width: 80px;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    .el-button {
      width: 200px;
    }
  }
  .setting {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #02a7f0;
  }
  .setting :nth-child(1) {
    margin-right: 12px;
  }
}
</style>
